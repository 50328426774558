<template>
  <el-dialog :title="title" :close-on-click-modal="false" :fullscreen="true" append-to-body :visible.sync="visible">
    <el-descriptions title="基本信息"></el-descriptions>
    <el-form size="small" :model="inputForm" ref="inputForm" :rules="dataRule" v-loading="loading"
             label-width="100px" :disabled="method==='view'">
      <el-form-item label="模板名称：" prop="archivesBasicName">
        <el-input v-model.trim="inputForm.archivesBasicName" maxlength="50" placeholder="请输入元数据模板名称（限50字）"
                  clearable></el-input>
      </el-form-item>
      <el-form-item label="父级模板名称" prop="parentId">
        <SelectTree v-if="visible"
                    ref="menuParentTree"
                    :props="defaultProps"
                    :data="templateList2"
                    :value="inputForm.parentId"
                    :clearable="true"
                    :accordion="true"
                    @getValue="(value) => {inputForm.parentId=value || '0'}"/>
      </el-form-item>
      <el-form-item label="简介：" prop="remakes">
        <el-input v-model.trim="inputForm.remakes" type="textarea" :rows="2" maxlength="200"
                  placeholder="请输入元数据模板简介（限200字）" clearable></el-input>
      </el-form-item>
      <el-descriptions title="元数据管理"></el-descriptions>
      <el-row class="text_center">
        <el-col :span="3" class="p_all1 b_d"><span class="redColor">*</span>元数据名称</el-col>
        <el-col :span="2" class="p_all1 b_r b_t b_b">别名</el-col>
        <el-col :span="2" class="p_all1 b_r b_t b_b">元数据类型</el-col>
        <el-col :span="2" class="p_all1 b_r b_t b_b"><span class="redColor">*</span>是否必填</el-col>
        <el-col :span="2" class="p_all1 b_r b_t b_b"><span class="redColor">*</span>查询条件</el-col>
        <el-col :span="2" class="p_all1 b_r b_t b_b"><span class="redColor">*</span>列表展示</el-col>
        <el-col :span="2" class="p_all1 b_r b_t b_b">时间格式</el-col>
        <el-col :span="3" class="p_all1 b_r b_t b_b">最小值</el-col>
        <el-col :span="3" class="p_all1 b_r b_t b_b">最大值</el-col>
        <el-col :span="2" class="p_all1 b_r b_t b_b" v-if="method != 'view'">操作</el-col>
      </el-row>
      <draggable v-model="inputForm.archivesBasicDataGroup" @update="moveList">
        <el-row v-for="(item, index) in inputForm.archivesBasicDataGroup" :key="index"
                class="dataRow text_center" v-loading="tableLoading">
          <el-col :span="3" class="b_r b_b b_l">
            <el-form-item :prop="`archivesBasicDataGroup.${index}.archivesBasicId`"
                          :rules="[{required: true, message: '元数据名称不能为空', trigger: 'change'}]"
                          label-width="0">
              <el-select v-model="item.archivesBasicId" placeholder="请选择"
                         v-if="!tableLoading" filterable @change="archivesBasicIdChange(item)"
                         :disabled="item.required == 1">
                <el-option v-for="item in archivesBasicOpt" :key="item.archivesBasicId" :label="item.basicName"
                           :value="item.archivesBasicId" :disabled="item.disabled"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="2" class="b_r b_b">
            <el-form-item :prop="`archivesBasicDataGroup.${index}.forName`" label-width="0">
              <el-input size="small" v-model="item.forName" placeholder="限10字"
                        maxlength="10" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2" class="b_r b_b">
            <el-form-item :prop="`archivesBasicDataGroup.${index}.archivesBasicType`" label-width="0">
              {{ item.archivesBasicType }}
            </el-form-item>
          </el-col>
          <el-col :span="2" class="b_r b_b">
            <el-form-item :prop="`archivesBasicDataGroup.${index}.dataRequired`" label-width="0">
              <el-switch v-model="item.dataRequired"
                         active-value="0" inactive-value="1"
              ></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="2" class="b_r b_b">
            <el-form-item :prop="`archivesBasicDataGroup.${index}.dataWhere`" label-width="0">
              <el-switch v-model="item.dataWhere"
                         active-value="0" inactive-value="1" :disabled="item.required == 1"
              ></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="2" class="b_r b_b">
            <el-form-item :prop="`archivesBasicDataGroup.${index}.dataSelect`" label-width="0">
              <el-switch v-model="item.dataSelect"
                         active-value="0" inactive-value="1" :disabled="item.required == 1"
              ></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="2" class="b_r b_b">
            <el-form-item :prop="`archivesBasicDataGroup.${index}.dataFormat`" label-width="0">
              <el-select v-model="item.dataFormat" placeholder="请选择" clearable
                         :disabled="item.archivesBasicType != '时间'" @change="dataFormatChange(item)">
                <el-option label="YYYY-MM-DD hh:mm:ss" value="yyyy-MM-dd HH:mm:ss"></el-option>
                <el-option label="YYYY-MM-DD" value="yyyy-MM-dd"></el-option>
                <el-option label="YYYYMMDD" value="yyyyMMdd"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3" class="b_r b_b">
            <el-form-item :prop="`archivesBasicDataGroup.${index}.minLength`" label-width="0">
              <el-input v-model="item.minLength"
                        @input="getNumType(item, 'minLength')"
                        @blur="minLengthChange(item)"
                        maxlength="11" clearable v-if="item.archivesBasicType != '时间'"
                        :disabled="!item.archivesBasicId"></el-input>
              <el-date-picker
                  v-model="item.minLength" value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd HH:mm:ss"
                  type="datetime" clearable @blur="timeChange(item)"
                  v-if="item.dataFormat == 'yyyy-MM-dd HH:mm:ss'" class="w100i">
              </el-date-picker>
              <el-date-picker
                  v-model="item.minLength" value-format="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                  type="date" clearable @blur="timeChange(item)"
                  v-if="item.dataFormat == 'yyyy-MM-dd'"
                  class="w100i">
              </el-date-picker>
              <el-date-picker
                  v-model="item.minLength" value-format="yyyyMMdd"
                  format="yyyyMMdd"
                  type="date" clearable @blur="timeChange(item)"
                  v-if="item.dataFormat == 'yyyyMMdd'"
                  class="w100i">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="3" class="b_r b_b">
            <el-form-item :prop="`archivesBasicDataGroup.${index}.maxLength`" label-width="0">
              <el-input v-model="item.maxLength"
                        @input="getNumType(item, 'maxLength')"
                        @blur="maxLengthChange(item)"
                        maxlength="11" clearable v-if="item.archivesBasicType != '时间'"
                        :disabled="!item.archivesBasicId"></el-input>
              <el-date-picker
                  v-model="item.maxLength" value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd HH:mm:ss"
                  type="datetime" clearable @blur="timeChange(item)"
                  v-if="item.dataFormat == 'yyyy-MM-dd HH:mm:ss'" class="w100i">
              </el-date-picker>
              <el-date-picker
                  v-model="item.maxLength" value-format="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                  type="date" clearable @blur="timeChange(item)"
                  v-if="item.dataFormat == 'yyyy-MM-dd'"
                  class="w100i">
              </el-date-picker>
              <el-date-picker
                  v-model="item.maxLength" value-format="yyyyMMdd"
                  format="yyyyMMdd"
                  type="date" clearable @blur="timeChange(item)"
                  v-if="item.dataFormat == 'yyyyMMdd'"
                  class="w100i">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="2" class="b_r b_b" v-if="method != 'view'">
            <el-form-item label-width="0">
              <el-button type="text" size="mini"
                         v-if="hasPermissionButton('collection:templateList:requiredDelete') || item.required != 1"
                         @click="delMetadataRow(index)">删除
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </draggable>
      <div class="text_center p_t1">
        <el-button size="small" type="primary" @click="addMetadataRow()" v-if="method != 'view'"
                   :disabled="inputForm.archivesBasicDataGroup.length >= 99">添加元数据
        </el-button>
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer text_center">
      <el-button size="small" @click="visible = false">取消</el-button>
      <el-button size="small" type="primary" @click="doSubmit()" v-if="method != 'view'"
                 v-noMoreClick>保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import SelectTree from '@/components/treeSelect/treeSelect.vue'
import draggable from "vuedraggable";

export default {
  components: {
    SelectTree, draggable
  },
  data() {
    return {
      id: '',
      title: '',
      visible: false,
      method: '',
      loading: false,
      defaultProps: {
        value: 'id',
        label: 'archivesBasicName',
        children: 'children',
      },
      inputForm: {
        id: '',
        parentId: '0',
        archivesBasicName: '',
        remakes: '',
        archivesBasicDataGroup: []
      },
      metadataRow: {
        archivesBasicId: '',
        archivesBasicType: '',
        dataFormat: '',
        dataRequired: '0',
        dataSelect: '0',
        dataWhere: '0',
        forName: '',
        minLength: '',
        maxLength: '',
        sorts: '',
      },
      templateList: [],
      templateList2: [],//只展示第一级别
      archivesBasicOpt: [],
      selArchives: [],
      tableLoading: false,
      dataRule: {
        archivesBasicName: [
          {required: true, message: '名称不能为空', trigger: 'blur'},
          {validator: this.validator.isEmoji, trigger: 'blur'}
        ],
      },
    }
  },
  methods: {
    init(row, method) {
      this.loading = false
      this.method = method
      this.inputForm.id = ''
      this.inputForm.parentId = '0'
      if (row) {
        this.id = row.id
      }
      if (method === 'add') {
        this.title = '新增元数据模板'
      } else if (method === 'edit') {
        this.title = '修改元数据模板'
      } else if (method === 'view') {
        this.title = '查看元数据模板'
      }
      this.visible = true
      this.$nextTick(() => {
        this.$refs['inputForm'].resetFields()
        this.getTemplateList()
      })
    },
    // 获取查看信息
    getInfo() {
      this.$axios(this.api.collection.basicdataGetById + '/' + this.id).then(data => {
        if (data.status) {
          this.inputForm = data.data
          this.$nextTick(() => {
            this.filterBasicData()
          })
          this.inputForm.archivesBasicDataGroup.map((item, index) => {
            this.$set(item, 'sorts', index + 1)
          })
          this.setDisabledTreeData(this.templateList, this.inputForm.archivesBasicName)
        }
      })
    },
    // 获取必选元数据
    getList() {
      this.inputForm.archivesBasicDataGroup = []
      this.$axios(this.api.collection.selectRequiredArchivesBasic).then((res) => {
        if (res.status) {
          this.inputForm.archivesBasicDataGroup = res.data
          this.inputForm.archivesBasicDataGroup.map((item, index) => {
            this.$set(item, 'sorts', index + 1)
            if (item.archivesBasicTypeCode == 'date') {
              this.$set(item, 'dataFormat', 'yyyyMMdd')
            }
          })
          this.$nextTick(() => {
            this.filterBasicData()
          })
        }
      })
    },
    // 获取元数据名称选项
    getArchivesBasicOpt(type) {
      this.tableLoading = true
      this.$axios(this.api.collection.selectAllArchivesBasic).then((res) => {
        if (res.status) {
          this.tableLoading = false
          this.archivesBasicOpt = res.data
          if (this.method === 'add' && !type) {
            this.getList()
          }
          if (this.method === 'edit' || this.method === 'view') {
            this.$nextTick(() => {
              this.getInfo()
            })
          }
        }
      })
    },
    // 设置禁选（不能选择当前模板名称）
    setDisabledTreeData(data, name) {
      data.map((item) => {
        if (item.archivesBasicName == name) {
          this.$set(item, "disabled", true);
        }
        let isFatherNode = item.children && item.children.length > 0;
        isFatherNode && this.setDisabledTreeData(item.children, name);
        let isHadDisabledChild = isFatherNode && item.children.some((it) => it.archivesBasicName == name);
        if (isHadDisabledChild) this.$set(item, "disabled", true);
      });
    },
    // 获取模板列表
    getTemplateList() {
      this.$axios(this.api.collection.selectArchivesBasicData, {}, 'post').then((res) => {
        if (res.status) {
          let arr = [];
          res.data.forEach(i => {
            if (i.children && i.children.length) {
              i.children.forEach((j) => {
                if (j.children && j.children.length) {
                  j.children.forEach((k) => {
                    if (k.children && k.children.length) {
                      k.children.forEach((l) => {
                        if (l.children && l.children.length) {
                          delete l.children;
                        }
                      });
                    }
                  });
                }
              });
            }
            arr.push(i);
          })
          this.templateList = arr
          this.templateList2 = arr.map(item=>{
            this.$delete(item,'children')
            return item
          })
          this.getArchivesBasicOpt()
        }
      })
    },
    // 时间格式修改
    dataFormatChange(item) {
      this.$set(item, 'minLength', '')
      this.$set(item, 'maxLength', '')
    },
    // 删除
    delMetadataRow(index) {
      this.inputForm.archivesBasicDataGroup.splice(index, 1);
      this.filterBasicData()
    },
    // 增加
    addMetadataRow() {
      let row = JSON.parse(JSON.stringify(this.metadataRow))
      row.sorts = this.inputForm.archivesBasicDataGroup.length + 1
      this.inputForm.archivesBasicDataGroup.push(row)
    },
    // 列表拖拽
    moveList() {
      this.inputForm.archivesBasicDataGroup.map((item, index) => {
        this.$set(item, 'sorts', index + 1)
      })
    },
    // 元数据名称修改
    archivesBasicIdChange(item) {
      this.$set(item, 'forName', '')
      this.$set(item, 'minLength', '')
      this.$set(item, 'maxLength', '')
      this.archivesBasicOpt.map(v => {
        if (v.id == item.archivesBasicId) {
          this.$set(item, 'archivesBasicType', v.archivesBasicType)
          this.$set(item, 'basicName', v.basicName)
        }
      })
      this.$nextTick(() => {
        if (item.archivesBasicType == '时间') {
          this.$set(item, 'dataFormat', 'yyyyMMdd')
        } else {
          this.$set(item, 'dataFormat', '')
        }
        this.filterBasicData()
      })
    },
    // 过滤元数据名称
    filterBasicData() {
      this.selArchives = []
      this.inputForm.archivesBasicDataGroup.map(v => {
        if (v.archivesBasicId) {
          this.selArchives.push(v.archivesBasicId)
        }
      })
      this.archivesBasicOpt.map(item => {
        this.$set(item, 'disabled', false)
      })
      this.$nextTick(() => {
        for (let id of this.selArchives) {
          let itemJson = this.archivesBasicOpt.find((item) => {
            return item.id === id
          });
          this.$set(itemJson, 'disabled', true)
        }
      })
    },
    // 最小值修改
    minLengthChange(item) {
      let {archivesBasicType, minLength, maxLength} = item
      let maxVal = 99999999
      let regular = /[^\d]/g
      if (item.archivesBasicType == '浮点数') {
        regular = /[^\d.]/g
      }
      let value = minLength
      if (value) {
        value = minLength.replace(regular, '')
      }
      if (archivesBasicType == '字符') {
        maxVal = 300
      }
      if (archivesBasicType == '数值' && value < 1 && value != '') {
        this.$message.warning('最小值必须大于等于1')
        this.$set(item, 'minLength', 1)
      } else if (archivesBasicType == '浮点数' && value != '' && !this.validate.isPrice(value)) {
        this.$message.warning('请输入正确的' + item.basicName + '，浮点数类型最多保留两位小数')
        this.$set(item, 'minLength', '')
      } else if (value != '' && value > maxVal) {
        this.$message.warning('最小值必须小于等于' + maxVal)
        if (maxLength) {
          this.$set(item, 'minLength', maxVal - 1)
          this.$set(item, 'maxLength', maxVal)
        } else {
          this.$set(item, 'minLength', maxVal)
        }
      } else if (maxLength && value != '' && value >= maxLength * 1) {
        this.$message.warning('最小值必须小于最大值')
        this.$set(item, 'minLength', maxLength * 1 - 1)
      } else {
        this.$set(item, 'minLength', value)
      }

    },
    // 最大值修改
    maxLengthChange(item) {
      let {archivesBasicType, minLength, maxLength} = item
      let maxVal = 99999999
      let regular = /[^\d]/g
      if (item.archivesBasicType == '浮点数') {
        regular = /[^\d.]/g
      }
      let value = maxLength.replace(regular, '')
      if (archivesBasicType == '字符') {
        maxVal = 300
      }
      if (archivesBasicType == '数值' && value < 1 && value != '') {
        this.$message.warning('最大值必须大于等于1')
        this.$set(item, 'maxLength', 1)
      } else if (archivesBasicType == '浮点数' && value != '' && !this.validate.isPrice(value)) {
        this.$message.warning('请输入正确的' + item.basicName + '，浮点数类型最多保留两位小数')
        this.$set(item, 'maxLength', '')
      } else if (value != '' && value > maxVal) {
        this.$message.warning('最大值必须小于等于' + maxVal)
        this.$set(item, 'maxLength', maxVal)
        if (minLength * 1 >= item.maxLength * 1) {
          minLength = maxVal - 1
          this.$set(item, 'minLength', maxVal - 1)
        }
      } else if (minLength && value != '' && value <= minLength * 1) {
        this.$message.warning('最大值必须大于最小值')
        if (minLength >= maxVal) {
          this.$set(item, 'maxLength', maxVal)
          this.$set(item, 'minLength', maxVal - 1)
        }
        if (minLength >= maxLength) {
          this.$set(item, 'minLength', (maxLength * 1) - 1)
        }
      } else {
        this.$set(item, 'maxLength', value)
      }
    },
    // 最大最小时间修改
    timeChange(item) {
      let {minLength, maxLength} = item
      if (minLength && new Date(maxLength) <= new Date(minLength)) {
        this.$message.warning('最大值时间必须大于最小值时间')
        this.$set(item, 'maxLength', '')
      } else if (maxLength && new Date(minLength) >= new Date(maxLength)) {
        this.$message.warning('最小值时间必须小于最大值时间')
        this.$set(item, 'minLength', '')
      }
    },
    // 获取元数据类型设置输入正则
    getNumType(item, type) {
      let {minLength, maxLength} = item
      let maxVal = 99999999
      let regular = /[^\d]/g
      if (item.archivesBasicType == '浮点数') {
        regular = /[^\d.]/g
      }
      if (item[type]) {
        this.$set(item, type, item[type].replace(regular, ''))
      }
      if (item[type] && type == 'minLength') {
        if (minLength >= maxVal) {
          this.$set(item, 'minLength', maxVal)
        }
        if (maxLength && minLength >= maxVal) {
          this.$set(item, 'maxLength', maxVal)
          this.$set(item, 'minLength', maxVal - 1)
        }
      }
      if (item[type] && type == 'maxLength') {
        if (maxLength >= maxVal) {
          this.$set(item, 'maxLength', maxVal)
        }
      }
    },
    // 提交
    doSubmit() {
      let param = JSON.parse(JSON.stringify(this.inputForm))
      // if (!param.parentId){
      //   param.parentId = '0'
      // }
      if (!param.archivesBasicDataGroup.length) {
        this.$message.error('请增加元数据')
        return
      }
      this.$refs['inputForm'].validate((valid) => {
        if (valid) {
          this.loading = true
          let api = this.api.collection.basicdataSave, type = 'post'
          if (this.method === 'edit') {  // 修改
            api = this.api.collection.basicdataUpdateById
            type = 'put'
          }
          this.$axios(api, param, type).then(data => {
            this.loading = false
            if (data && data.status) {
              this.$message.success(data.msg)
              this.visible = false
              this.$emit('refreshDataList')
            } else {
              this.$message.error(data.msg)
            }
          })
        }
      })
    },
  },
}
</script>

<style scoped>
.el-form-item--small.el-form-item {
  margin: 18px auto;
}

.dataRow .el-col {
  padding: 0 10px;
  height: 70px;
}
</style>
